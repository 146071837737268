<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="4">
              <date-picker
                v-model="searchData.dateRange"
                :shortcuts="false"
                :editable="false"
                :not-after="$moment()"
                range-separator="~"
                range
                width="100%"
                value-type="date"
                format="YYYY-MM-DD"
                :input-class="['form-control', 'bg-white']"
              />
            </CCol>
            <CCol col="12" sm="3">
              <CInput
                :placeholder="$t('search_a') + $t('single_number')"
                v-model="searchData.sn"
                v-on:keyup.enter="getQcReports()"
              />
            </CCol>
            <CCol col="12" sm="3">
              <CInput
                :placeholder="$t('search_a') + $t('device_id')"
                v-model="searchData.devid"
                v-on:keyup.enter="getQcReports()"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getQcReports()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="12" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="createdAt" slot-scope="props">
              {{ $moment(props.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="primary" v-bind="{ variant: 'outline' }" @click="() => qcReportPreviewModal = { data: props.row, modal: true }">{{ $t('preview') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <QcReportPreviewModal :data="qcReportPreviewModal.data" :show="qcReportPreviewModal.modal" @showUpdate="show => qcReportPreviewModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import QcReportPreviewModal from '../device/QcReportPreviewModal'

export default {
  name: 'qcReport',
  components: {
    QcReportPreviewModal
  },
  data () {
    return {
      columns: [ 'sn', 'devid', 'userName', 'createdAt', 'action' ],
      data: [],
      options: {
        headings: {
          sn: this.$t('single_number'),
          devid: this.$t('device_id'),
          userName: this.$t('user_name'),
          createdAt: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'sn', 'devid', 'userName', 'createdAt' ],
        filterable: [ 'sn', 'devid', 'userName' ]
      },
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { startAt: this.$moment({hour: 0}).unix(), endAt: this.$moment().endOf('d').unix(), dateRange: [this.$moment({hour: 0}), this.$moment({hour: 0})], sn: '', devid: '' },
      qcReportPreviewModal: { data: {}, modal: false }
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getQcReports () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.searchData.startAt = this.$moment(this.searchData.dateRange[0]).unix()
      this.searchData.endAt = this.$moment(this.searchData.dateRange[1]).endOf('d').unix()
      this.$store.dispatch('getQcReports', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
  }
}
</script>
