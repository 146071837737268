<template>
  <CModal
    :title="$t('preview') + ' - ' + $t('qc_report') + ' #' + modal.data.sn"
    color="primary"
    size="xl"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <div id="print" style="margin: 36px;font-size: 125%;">
      <CRow>
        <CCol col="6" sm="6">
          <font>正旻科技有限公司<br></font>
          <font>Chainmeans Technology Ltd.<br></font>
          <font>高雄市左營區立文路77號10樓<br></font>
          <font>10F., No. 77, Liwen Rd., Zuoying<br></font>
          <font>Dist., Kaohsiung City 813, Taiwan<br></font>
          <font>(R.O.C.)</font>
        </CCol>
        <CCol col="6" sm="6">
          <img src="img/enaoul-1024x1024.png" style="float: right;height: 126px;">
        </CCol>
      </CRow>
      <CRow>
        <CCol col="12" sm="12" class="text-center">
          <p class="h3 mb-0">{{ $t('qc_report') }}</p>
        </CCol>
      </CRow>
      <hr class="border border-dark">
      <font>
        產品名稱：雙模複合傳感車檢器
      </font><br>
      <font>
        產品型號：
        {{ modal.data.protocolVersion === 3 ? 'ES-T51' : '' }}
        {{ modal.data.protocolVersion === 20 ? 'ES-T70' : '' }}
        <br>
      </font>
      <font>
        單號：
        {{ modal.data.sn }}
      </font><br>
      <font>
        設備ID：
        {{ modal.data.devid }}
      </font><br>
      <font>
        測試人員：
        {{ modal.data.userName }}
      </font><br>
      <font>
        單據日期：
        {{ $moment(modal.data.createdAt).format('YYYY-MM-DD') }}
      </font>
      <hr class="border border-dark">
      <v-client-table :columns="table.columns" :data="table.data" :options="table.options">
        <CBadge slot="result" slot-scope="props" :color="$_.find(resultOptions, option => { return option.value === props.row.result }).color">
          {{ $_.find(resultOptions, option => { return option.value === props.row.result }).label }}
        </CBadge>
      </v-client-table>
    </div>
    <CButton slot="footer" color="primary" v-print="'#print'">列印</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'QcReportPreviewModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      table: {
        columns: ['name', 'result'],
        data: [],
        options: {
          headings: {
            name: this.$t('item_name'),
            result: this.$t('result')
          },
          filterable: false
        }
      },
      resultOptions: [
        { value: 1, label: 'Passed', color: 'success' },
        { value: 0, label: 'Failed', color: 'danger' },
        { value: -1, label: 'Untested', color: 'secondary' }
      ]
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      this.table.data = this.$_.sortBy(this.modal.data.results, 'item')
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    }
  },
  beforeDestroy: function () {
  },
}
</script>
